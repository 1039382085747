import axios from 'axios';
// import toast from 'shared/utils/toast';
import { getStoredAuthToken, removeStoredAuthToken } from '@/utils/authToken';
import { objectToQueryString } from '@/utils/url';
import { errorHandler } from './errorHandler';

const API_URL: { [key: string]: string } = {
  local: 'http://localhost:8082/1.0/proxy',
  // local: 'https://api.qatool.dev.al-array.com/1.0/proxy',
  development: 'https://api.qatool.dev.al-array.com/1.0/proxy',
  production: 'https://api.qatool.al-array.com/1.0/proxy',
};

const UNKNOWN_ERROR =
  'Something went wrong. Please check your internet connection or contact our support.';

export const apiDefaults = {
  baseURL: API_URL[STAGE || 'local'],
  headers: (method: string) => ({
    'Content-Type': method === 'get' ? 'text/plain' : 'application/json',
    Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : undefined,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: UNKNOWN_ERROR,
    status: 503,
    data: {},
  },
};

const isInLoginPage = window.location.hash.includes('login');

const api = (
  method: string,
  url: string,
  params: Record<string, unknown> = {},
  body: Record<string, unknown> = {},
): Promise<any> =>
  new Promise((resolve, reject) => {
    axios({
      baseURL: apiDefaults.baseURL,
      url: url,
      method,
      headers: apiDefaults.headers(method),
      params,
      data: body,
      paramsSerializer: objectToQueryString,
    }).then(
      response => {
        resolve(response.data);
      },
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            removeStoredAuthToken();
            if (!isInLoginPage) {
              window.location.hash = '/login';
              window.location.reload();
            }
          } else {
            const handler = errorHandler.default;
            handler(error.response.data.message || UNKNOWN_ERROR);
            reject(error.response.data.message);
            console.error(error.response.data.message);
          }
        } else {
          errorHandler.default(error.message || UNKNOWN_ERROR);
          reject(apiDefaults.error);
          console.error(apiDefaults.error);
        }
      },
    );
  });

export default {
  get: (url: string, params?: any) => api('get', url, params),
  post: (url: string, params?: any, body?: any) => api('post', url, params, body),
  put: (url: string, params?: any, body?: any) => api('put', url, params, body),
  patch: (url: string, params?: any, body?: any) => api('patch', url, params, body),
  delete: (url: string, params?: any, body?: any) => api('delete', url, params, body),
};

export const apis = {
  // getProjects: () => api('get', '/system/project'),
};
