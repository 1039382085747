import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { googleClientId } from '@/constants';
import './index.less';
import { storeAuthToken } from '@/utils/authToken';
import api from '@/utils/api';
import { useNavigate } from 'react-router-dom';
import localStorageManager from '@/utils/storage';
import { parseJWT } from '@/utils/jwt';
import Logo from '@/assets/imgs/logo.svg';

const API_URL: { [key: string]: string } = {
  // local: 'http://localhost:8080/1.0',
  local: 'https://api.silk.dev.al-array.com/1.0',
  development: 'https://api.silk.dev.al-array.com/1.0',
  production: 'https://api.silk.al-array.com/1.0',
};

export default () => {
  const navigate = useNavigate();

  const onGoogleLoginSuccess = async (response: any) => {
    try {
      const { accessToken } = await api.post(`${API_URL[STAGE || 'local']}/user/google/login`, {
        authorizationToken: response.credential,
      });
      if (accessToken) {
        storeAuthToken(accessToken);
        localStorageManager.set('user', parseJWT(accessToken).payload);
        navigate('/portal/device');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div className='banner'>
            <Logo />
            <p>QA Tool Platform</p>
          </div>

          <div className='google-login'>
            <GoogleLogin onSuccess={onGoogleLoginSuccess} width={'345px'} theme='outline' />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};
