import { Button, Form, InputNumber, Checkbox, message } from 'antd';
import { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import api from '@/utils/api';

import './index.less';

const FormCheckbox = (props: any) => {
  const { value, onChange, ...rest } = props;

  const onCheckboxChange = (e: any) => {
    onChange(e.target.checked);
  };

  return <Checkbox {...rest} checked={value} onChange={onCheckboxChange}></Checkbox>;
};

export default () => {
  const [networkForm] = useForm();
  const { deviceId } = useParams<{ deviceId: string }>();

  const getNetworkInfo = async () => {
    const res = await api.get('/networkThrottle', { deviceId }).catch(() => ({ enable: false }));

    if (res) {
      networkForm.setFieldsValue(res);
    }
  };

  useEffect(() => {
    getNetworkInfo();
  }, []);

  const onSubmit = async () => {
    try {
      const res = await networkForm.validateFields();
      const success = await api.post('/networkThrottle', {}, { ...res, deviceId });
      if (success) {
        message.success('Submit success');
      } else {
        message.error('Submit failed');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className='network-config-page'>
      <Form form={networkForm}>
        <Form.Item name='enable'>
          <FormCheckbox>Enable throttling</FormCheckbox>
        </Form.Item>

        <Form.Item name='bytePerSecond'>
          <InputNumber addonAfter='bytes per second' min={1} />
        </Form.Item>
      </Form>

      <div className='footer'>
        <Button type='primary' onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};
