import localStorageManager from '@/utils/storage';
import { Descriptions, Tabs } from 'antd';
import type { DescriptionsProps, TabsProps } from 'antd';
import ResponseConfig from './ResponseConfig';
import ModificationConfig from './ModificationConfig';
import NetworkConfig from './NetworkConfig';

import './index.less';

const tabItems: TabsProps['items'] = [
  {
    key: '1',
    label: 'Config Modification',
    children: <ModificationConfig />,
  },
  {
    key: '2',
    label: 'API Response',
    children: <ResponseConfig />,
  },
  {
    key: '3',
    label: 'Network Throttle',
    children: <NetworkConfig />,
  },
];

export default () => {
  const deviceInfo = localStorageManager.get('previewDevice') || {};

  const items: DescriptionsProps['items'] = [
    {
      key: 'idfa',
      label: 'IDFA',
      children: deviceInfo.idfa,
    },
    {
      key: 'carrier',
      label: 'Carrier',
      children: deviceInfo.carrier,
    },
    {
      key: 'brand',
      label: 'Brand',
      children: deviceInfo.brand,
    },
    {
      key: 'osVersion',
      label: 'OS Version',
      children: deviceInfo.osVersion,
    },
    {
      key: 'deviceModel',
      label: 'Device Model',
      children: deviceInfo.model,
    },
    {
      key: 'chipManufacturer',
      label: 'Chip Manufactor',
      children: deviceInfo.chipManufacturer,
    },
    {
      key: 'store',
      label: 'Country',
      children: deviceInfo.store,
    },
  ];

  return (
    <div className='device-detail-page'>
      <div className='banner'>
        <Descriptions title='Device Info' items={items} />
      </div>
      <div className='content'>
        <Tabs items={tabItems} defaultActiveKey='1' />
      </div>
    </div>
  );
};
