import { Table, Button, Modal, Switch, Form, Select, Input, FormInstance, message } from 'antd';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import differenceWith from 'lodash/differenceWith';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import FileUpload from '@/components/FileUpload';
import FileExport from '@/components/FileExport';
import api from '@/utils/api';
import { v4 as uuidv4 } from 'uuid';
import countryList from 'react-select-country-list';
import './index.less';

enum InputType {
  Input = 'Input',
  MultInput = 'MultInput',
  Select = 'Select',
  Switch = 'Switch',
}

interface EditableCellProps {
  title: React.ReactNode;
  children: React.ReactNode;
  dataIndex: any;
  editable?: boolean;
  record: any;
  // selectedConfigNames: any[];
  // setSelectedConfigNames: any;
  handleSave: (record: any) => void;
}

interface EditableRowProps {
  index: number;
}

const configNameMap = {
  deviceConfig: [
    {
      configName: 'country',
      title: 'Country',
    },
    {
      configName: 'device_model',
      title: 'Device Model',
    },
    {
      configName: 'store_region',
      title: 'Realme Country',
    },
    {
      configName: 'apphub_version_code',
      title: 'AppHub Version Code',
    },
    {
      configName: 'api_level',
      title: 'API Level',
    },
  ],
  featureConfig: [
    {
      configName: 'oobe_enable',
      title: 'Enable OOBE',
    },
    // {
    //   configName: 'oobe_delay',
    //   title: 'OOBE Display Delay',
    // },
    // {
    //   configName: 'oobe_intervals',
    //   title: 'OOBE Delay Intervals',
    // },
    // {
    //   configName: 'late_oobe_settings_ui_type',
    //   title: 'Late OOBE UI type',
    // },
    // {
    //   configName: 'oobe_os_update_settings_max_frequency',
    //   title: 'OOBE/OSUpdate Max frequency',
    // },
    // {
    //   configName: 'oobe_os_update_settings_time_period',
    //   title: 'OOBE/OSUpdate Max frequency',
    // },
    {
      configName: 'oobeReminder_enable',
      title: 'Enable OOBE Reminder Notification',
    },
    {
      configName: 'oobeReminder_intervals',
      title: 'OOBE Reminder Notification Intervals',
    },
    {
      configName: 'oobeReminder_settings_persistent',
      title: 'OOBE Reminder Notification Persistent',
    },
    {
      configName: 'osUpdate_enable',
      title: 'Enable OSUpdate',
    },
    {
      configName: 'osUpdate_delay',
      title: 'OSUpdate Display Delay',
    },
    {
      configName: 'osUpdate_intervals',
      title: 'OSUpdate Delay Intervals',
    },
    {
      configName: 'osUpdateReminder_enable',
      title: 'Enable OSUpdate Reminder Notification',
    },
    {
      configName: 'osUpdateReminder_intervals',
      title: 'OSUpdate Reminder Notification Intervals',
    },
    {
      configName: 'osUpdateReminder_settings_persistent',
      title: 'OSUpdate Reminder Notification Persistent',
    },
    {
      configName: 'directDownload_enable',
      title: 'Enable Direct Download',
    },
    {
      configName: 'client_info_force_update',
      title: 'Force Update',
    },
    {
      configName: 'client_info_allowed_over_mobile_data',
      title: 'Self Update',
    },
  ],
};

const getConfigNameOptinos = (selectedConfigNames: string[]) => [
  {
    label: 'Device Config',
    options: configNameMap.deviceConfig
      .filter(item => !selectedConfigNames.includes(item.configName))
      .map(item => ({
        label: item.title,
        value: item.configName,
      })),
  },
  {
    label: 'Feature Config',
    options: configNameMap.featureConfig
      .filter(item => !selectedConfigNames.includes(item.configName))
      .map(item => ({
        label: item.title,
        value: item.configName,
      })),
  },
];

const getConfigValueOptinos = (configName: string) => {
  switch (configName) {
    case 'country':
      // console.log('countryList', countryList().getData());
      return countryList()
        .getData()
        .map(item => ({
          label: `(${item.value}) ${item.label}`,
          value: item.value,
        }));
    case 'store_region':
      return [
        {
          label: '(IN) India',
          value: 'IN',
        },
        {
          label: '(ID) Indonesia',
          value: 'ID',
        },
        {
          label: '(RU) Russian Federation',
          value: 'RU',
        },
        {
          label: '(TH) Thailand',
          value: 'TH',
        },
        {
          label: '(VN) Viet Nam',
          value: 'VN',
        },
        {
          label: '(MY) Malaysia',
          value: 'MY',
        },
        {
          label: '(PH) Philippines',
          value: 'PH',
        },
      ];

    case 'osUpdate_intervals':
    case 'osUpdateReminder_intervals':
      return [
        {
          label: '100',
          value: '100',
        },
        {
          label: '200',
          value: '200',
        },
        {
          label: '300',
          value: '300',
        },
      ];

    default:
      return [];
  }
};

const configNameInputType = {
  'country': InputType.Select,
  'device_model': InputType.Input,
  'store_region': InputType.Select,
  'apphub_version_code': InputType.Input,
  'api_level': InputType.Input,
  'oobe_enable': InputType.Switch,
  // 'oobe_delay': InputType.Switch,
  // 'oobe_intervals': InputType.Input,
  // 'late_oobe_settings_ui_type': InputType.Select,
  // 'oobe_os_update_settings_max_frequency': InputType.Input,
  // 'oobe_os_update_settings_time_period': InputType.Input,
  'oobeReminder_enable': InputType.Switch,
  'oobeReminder_intervals': InputType.MultInput,
  'oobeReminder_settings_persistent': InputType.Switch,
  'osUpdate_enable': InputType.Switch,
  'osUpdate_delay': InputType.Switch,
  'osUpdate_intervals': InputType.MultInput, // 数组
  'osUpdateReminder_enable': InputType.Switch,
  'osUpdateReminder_intervals': InputType.MultInput, // 数组
  'osUpdateReminder_settings_persistent': InputType.Switch,
  'directDownload_enable': InputType.Switch,
  'client_info_force_update': InputType.Switch,
  'client_info_allowed_over_mobile_data': InputType.Switch,
};

const defaultAddItem = {
  configName: 'country',
  configValue: getConfigValueOptinos('country')[0].value,
};

const getDefaultConfigValue = (configName: keyof typeof configNameInputType) => {
  const componentType = configNameInputType[configName];

  switch (componentType) {
    case InputType.Switch:
      return false;
    default:
      return '';
  }
};

const renderInputComponent = (
  configName: keyof typeof configNameInputType,
  dataIndex: string,
  save: () => void,
) => {
  const componentType = configNameInputType[configName];
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  switch (componentType) {
    case InputType.Input:
      return (
        <Form.Item name={dataIndex}>
          <Input onBlur={save} />
        </Form.Item>
      );
    case InputType.MultInput:
      return (
        <Form.Item name={dataIndex}>
          <Input onBlur={save} placeholder='[60,60,60]' />
        </Form.Item>
      );
    case InputType.Select:
      return (
        <Form.Item name={dataIndex}>
          <Select
            showSearch={true}
            onSelect={save}
            options={getConfigValueOptinos(configName)}
            filterOption={filterOption}></Select>
        </Form.Item>
      );
    case InputType.Switch:
      return (
        <Form.Item name={dataIndex}>
          <Switch onChange={save} />
        </Form.Item>
      );
  }
};

const EditableContext = React.createContext<FormInstance | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  children,
  dataIndex,
  record,
  handleSave,
  // selectedConfigNames,
  // setSelectedConfigNames,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;

  const save = async (selectedValue?: any) => {
    const values = await form.validateFields().catch(err => ({}));
    if (dataIndex === 'configName') {
      form.resetFields();
      form.setFieldsValue({
        configValue: getDefaultConfigValue(selectedValue),
      });
    }
    form.setFieldsValue({ [dataIndex]: values[dataIndex] });

    handleSave({ ...record, ...form.getFieldsValue() });
  };

  let childNode = children;

  useEffect(() => {
    if (!record) return;
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
    // setSelectedConfigNames((pre: any) => [...pre, record.configName]);
  }, []);

  if (dataIndex === 'configName') {
    childNode = (
      <Form.Item name={dataIndex}>
        <Select onSelect={save} options={getConfigNameOptinos([])}></Select>
      </Form.Item>
    );
  }

  if (dataIndex === 'configValue') {
    childNode = (
      <Form.Item name={dataIndex}>
        {renderInputComponent(record.configName, dataIndex, save)}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [initialDataSource, setInitialDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const [selectedConfigNames, setSelectedConfigNames] = useState<string[]>([]);

  const getConfigList = async () => {
    setLoading(true);
    const res = await api.get('/paramConfigList', { deviceId }).catch(() => []);
    const list = res.map((item: any) => ({
      ...item,
      id: uuidv4(),
    }));

    if (res) {
      setDataSource(list);
      setInitialDataSource(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    getConfigList();
  }, []);

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    // setSelectedConfigNames(pre => [...pre, row.configName]);
  };

  const deleteApi = (record: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => record.id === item.id);
    newData.splice(index, 1);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns = [
    {
      title: 'Config Name',
      dataIndex: 'configName',
      width: 400,
    },
    {
      title: 'Config Value',
      dataIndex: 'configValue',
      width: 400,
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_: any, record: any) => (
        <div className='actions-item'>
          <Button type='link' onClick={() => deleteApi(record)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const columns = defaultColumns.map(item => ({
    ...item,
    onCell: (record: any) => ({
      record,
      // selectedConfigNames,
      // setSelectedConfigNames,
      dataIndex: item.dataIndex,
      title: item.title,
      handleSave,
    }),
  }));

  const onAddApi = () => {
    const newData = [...dataSource];
    newData.push({
      isAdded: true,
      id: uuidv4(),
      ...defaultAddItem,
    });
    setDataSource(newData);
  };

  const onSubmit = async () => {
    const configNameList = dataSource.map(item => item.configName);
    const configValueList = dataSource.map(item => item.configValue);
    const configNameSet = new Set([...configNameList]);
    if (configNameList.length !== configNameSet.size) {
      message.error('Config name cannot be repeated');
      return;
    }

    if (configValueList.some(item => item === '')) {
      message.error('Config value cannot be empty');
      return;
    }

    const list = dataSource.map(item => ({
      configName: item.configName,
      configValue: item.configValue,
    }));

    let success = true;
    try {
      const res = await api.post(
        '/paramConfigList',
        {},
        {
          deviceId,
          configList: list,
        },
      );
      success = !!res;
      if (success) {
        message.success('Submit success');
        getConfigList();
      } else {
        message.error('Submit failed');
      }
    } catch (error) {
      // message.error('Submit failed');
    }
  };

  const onClearChange = () => {
    setDataSource(initialDataSource);
  };

  const onImportFile = (data: any) => {
    const importedData = data.map((item: any) => ({
      ...item,
      id: uuidv4(),
      isAdded: true,
    }));
    setDataSource(pre => [...pre, ...importedData]);
  };

  return (
    <div className='modification-config'>
      <div className='top'>
        <div className='right'>
          <FileUpload onChange={onImportFile} />
          <FileExport
            jsonData={dataSource.map(item => ({
              configName: item.configName,
              configValue: item.configValue,
            }))}
          />
          <Button type='primary' className='add-btn' onClick={onAddApi}>
            Add
          </Button>
        </div>
      </div>
      <Table
        columns={columns as any}
        rowClassName={() => 'editable-row'}
        components={components}
        dataSource={dataSource}
        rowKey={(record: any) => `${record.configName}_${record.id || ''}`}
        pagination={false}
        loading={loading}
      />

      <div className='bottom'>
        <div className='right'>
          <Button type='primary' className='submit-btn' onClick={onSubmit}>
            Submit
          </Button>

          <Button type='primary' className='clear-btn' onClick={onClearChange}>
            Clean all changes
          </Button>
        </div>
      </div>
    </div>
  );
};
