import { Table, Space, Button, Modal, Form, Select, Input, message } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { useAntdTable } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import localStorageManager from '@/utils/storage';
import api from '@/utils/api';

import './index.less';
import { useForm } from 'antd/es/form/Form';

export default () => {
  const [filterForm] = useForm();
  const [apiForm] = useForm();
  const currentApiRef = useRef<any>({});
  const navigate = useNavigate();
  const deviceInfo = localStorageManager.get('previewDevice') || {};
  const [refreshTable, setRefreshTable] = useState(false);
  const { deviceId } = deviceInfo;

  const getTableData = async () => {
    // return Promise.resolve(testData);
    const res = await api.get('/ruleList', { deviceId }).catch(() => null);

    return {
      total: res.length,
      list: res,
    };
  };

  const columns = [
    {
      title: 'Method',
      dataIndex: 'method',
      width: 150,
    },
    {
      title: 'Url',
      dataIndex: 'httpUrl',
      width: 150,
    },
    {
      title: 'Response',
      dataIndex: 'httpResponse',
      width: 500,
      render: (text: string) => <div className='response-content'>{text}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 250,
      render: (_: any, record: any) => (
        <div className='actions-item'>
          <Button type='link' onClick={() => onPreviewApi(record)}>
            Preview
          </Button>
          <Button type='link' onClick={() => onEditApi(record)}>
            Edit
          </Button>
          <Button type='link' onClick={() => onDeleteApi(record)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const onAddApi = () => {
    currentApiRef.current = {};
    setShowModal(true);
  };

  const onEditApi = (record: any) => {
    currentApiRef.current = record;
    apiForm.setFieldsValue(record);
    setShowModal(true);
  };

  const onPreviewApi = (record: any) => {
    currentApiRef.current = { preview: true };
    apiForm.setFieldsValue(record);
    setDisabledForm(true);
    setShowModal(true);
  };

  const onDeleteApi = (record: any) => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure to delete this api?',
      onOk: async () => {
        await api.delete(`/rule?id=${record.id}`).catch(() => null);
        setRefreshTable(val => !val);
      },
    });
  };

  const { tableProps, search } = useAntdTable(getTableData, {
    form: filterForm,
    refreshDeps: [refreshTable],
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  // console.log(tableProps);

  const handleOk = async () => {
    if (currentApiRef.current.preview) {
      setConfirmLoading(false);
      resetModal();
      return;
    }

    try {
      const values = await apiForm.validateFields();
      console.log(values);
      setConfirmLoading(true);
      const { id } = currentApiRef.current;
      const success = await api
        .post('/ruleList', {}, [{ ...values, id, deviceId }])
        .catch(() => null);
      if (success) {
        message.success('Submit success');
      } else {
        message.error('Submit failed');
      }
      setConfirmLoading(false);
      resetModal();
      setRefreshTable(val => !val);
    } catch (e) {
      console.log(e);
    }
  };

  const resetModal = () => {
    setShowModal(false);
    setDisabledForm(false);
    apiForm.resetFields();
  };

  return (
    <div className='response-config'>
      <div className='actions'>
        <Button type='primary' className='add-btn' onClick={onAddApi}>
          Add
        </Button>
      </div>

      <div className='api-list'>
        <Table columns={columns} rowKey='id' {...tableProps} />
      </div>

      <Modal
        title='Title'
        width={650}
        open={showModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={resetModal}>
        <Form
          form={apiForm}
          labelCol={{ span: 3, offset: 1 }}
          disabled={disabledForm}
          className='api-edit'>
          <Form.Item label='Method' name='method' rules={[{ required: true }]}>
            <Select>
              <Select.Option value='get'>Get</Select.Option>
              <Select.Option value='post'>Post</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label='Url' name='httpUrl' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label='Response' name='httpResponse' rules={[{ required: true }]}>
            <Input.TextArea className='response-editor' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
