import Header from '@/components/Header/Header';
import Menu from '@/components/SideBarMenu';
import { colors } from '@/constants/color';
import { apis } from '@/utils/api';
import { Divider, FloatButton, Layout, Select, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './RootPage.less';
import { CommentOutlined, CustomerServiceOutlined, FormOutlined } from '@ant-design/icons';

const fullScreenPages = ['login'];

export default () => {
  const [isCollapse, setIsCollapse] = useState(false);

  const isFullScreenPage = useMemo(
    () => !!fullScreenPages.find(path => location.pathname.includes(path)),
    [location],
  );

  return (
    <Layout style={{ height: '100vh' }}>
      {!isFullScreenPage && (
        <Layout.Header style={{ backgroundColor: colors.primaryColor }}>
          <Header />
        </Layout.Header>
      )}

      <Layout>
        {!isFullScreenPage && (
          <Layout.Sider theme='light' collapsible={true} onCollapse={setIsCollapse}>
            <div style={{ width: !isCollapse ? '200px' : 'auto', height: '80vh' }}>
              <Menu />
            </div>
          </Layout.Sider>
        )}

        <Layout className='mainPage'>
          <Layout.Content>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
