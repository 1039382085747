import { removeEmpty } from '@/utils/object';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, FormInstance, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/es/select';
import './index.less';

interface FilterItem {
  type: 'Select' | 'Input' | 'Date';
  name: string;
  label: string;
  placeholder?: string;
  options?: DefaultOptionType[];
  style?: React.CSSProperties;
}

interface FilterProps {
  form?: FormInstance;
  items: FilterItem[];
  onQuery: (...args: any[]) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, ...filters: any[]) => void;
  loading?: boolean;
}
export default (props: FilterProps) => {
  const [filterForm] = useForm();
  const { form = filterForm, items, onQuery } = props;

  const resetHandler = () => {
    form.resetFields();
  };

  const queryHandler = () => {
    const filters = form.getFieldsValue();
    onQuery(removeEmpty(filters));
  };

  return (
    <div className='filters'>
      <Form form={form} layout='inline'>
        {items.map(item => (
          <Form.Item {...item} key={item.name}>
            {item.type === 'Select' && (
              <Select
                options={item.options}
                placeholder={item.placeholder || 'Please select'}
                allowClear={true}
                style={item.style || {}}
              />
            )}
            {item.type === 'Input' && (
              <Input
                placeholder={item.placeholder || 'Please input'}
                style={item.style || {}}
                onKeyDown={e =>
                  props.onKeyDown && props.onKeyDown(e, removeEmpty(form.getFieldsValue()))
                }
              />
            )}
            {item.type === 'Date' && (
              <DatePicker
                placeholder={item.placeholder || 'Select Date'}
                style={item.style || {}}
              />
            )}
          </Form.Item>
        ))}
      </Form>
      <div className='button-group'>
        <Button onClick={resetHandler} style={{ marginRight: '8px' }}>
          Reset
        </Button>
        <Button
          onClick={queryHandler}
          type='primary'
          loading={props.loading}
          icon={<SearchOutlined />}>
          Query
        </Button>
      </div>
    </div>
  );
};
