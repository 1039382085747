import { Button } from 'antd';

type Props = {
  accept?: string;
  onChange: (data: any) => void;
};
export default (props: Props) => {
  const { accept = 'application/json' } = props;

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      try {
        const jsonObj = JSON.parse(event.target.result);
        props.onChange(jsonObj);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    reader.readAsText(file);
  };

  const onClickBtn = () => {
    const element: any = document.querySelector('#upload');

    if (element) {
      element.value = '';
      element.click();
    }
  };

  return (
    <>
      <Button type='primary' className='upload-btn' onClick={onClickBtn}>
        Import
      </Button>
      <input
        type='file'
        id='upload'
        accept={accept}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
