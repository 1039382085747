import { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import type { MenuProps as AntdMenuProps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { RouteConfig } from '@/interface/router';
import { routeConfigs } from '@/routers';

const getMenuItems = (config: RouteConfig[]): (RouteConfig & { key: string; lable: string })[] =>
  config.reduce(
    (acc: any[], cur: RouteConfig) => [
      ...acc,
      { ...cur, label: cur.title, key: cur.title },
      ...getMenuItems(cur.children || []),
    ],
    [],
  );

// console.log(routeConfigs); // TODO: 确认这里为什么是undefined

export default () => {
  const [selectedKey, setSelectedKey] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = useMemo(() => {
    return getMenuItems(routeConfigs || [])?.filter(item => !item.hidden);
  }, [routeConfigs]);

  const onClickMenu: AntdMenuProps['onClick'] = e => {
    const selectedPath = menuItems.find(item => item.key === e.key)?.path;
    if (selectedPath) {
      navigate(selectedPath);
    }
  };

  useEffect(() => {
    const selectedKey = menuItems.find(item => location.pathname.includes(item.path))?.key;
    if (selectedKey) {
      setSelectedKey(selectedKey);
    }
  }, [location]);

  return <Menu items={menuItems} onClick={onClickMenu} selectedKeys={[selectedKey]} />;
};
