import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Routes } from '@/routers';
import { colors } from '@/constants/color';

import 'antd/dist/reset.css';

const App = () => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: colors.primaryColor } }}>
      <HashRouter>
        <Routes />
      </HashRouter>
    </ConfigProvider>
  );
};

export default App;
