import { RouteConfig } from '@/interface/router';
import RootPage from '@/pages/RootPage/RootPage';
import {
  AlertOutlined,
  CustomerServiceOutlined,
  FileExclamationOutlined,
  FileSearchOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import DeviceListPage from '@/pages/DeviceList';
import DeviceDetailPage from '@/pages/DeviceDetail';
import LoginPage from '@/pages/Login';

const pages = () => {
  const children = [
    {
      path: 'device',
      title: 'Device List',
      icon: <ProfileOutlined />,
      component: <DeviceListPage />,
    },
    {
      path: 'device/detail/:deviceId',
      title: 'DeviceDetail',
      icon: <ProfileOutlined />,
      hidden: true,
      component: <DeviceDetailPage />,
    },
  ];

  return children;
};

export const routeConfigs: RouteConfig[] = [
  {
    path: '/portal/',
    title: '',
    hidden: true,
    component: <RootPage />,
    children: pages(),
  },
  {
    path: '/login',
    title: 'Login',
    hidden: true,
    component: <LoginPage />,
  },
];

export const renderRouter = (routes: RouteConfig[]): any => {
  return routes.map(route => {
    return (
      <Route key={route.path} path={route.path} element={route.component}>
        {renderRouter(route.children || [])}
      </Route>
    );
  });
};

export const Routes: FC = () => {
  return (
    <ReactRouterRoutes>
      {renderRouter(routeConfigs)}
      <Route path='*' element={<Navigate to={'/portal/device'} replace />} />
    </ReactRouterRoutes>
  );
};
