import { Button } from 'antd';
export default ({ jsonData }: { jsonData: any }) => {
  const onExport = () => {
    const jsonStr = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  return (
    <Button type='primary' className='export-btn' onClick={onExport}>
      Export
    </Button>
  );
};
