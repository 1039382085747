import localStorageManager from '@/utils/storage';
import { useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps, Space } from 'antd';
import { useState } from 'react';
import './Header.less';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

const items: MenuProps['items'] = [
  {
    label: 'Sign Out',
    key: 'signOut',
    icon: <LogoutOutlined />,
  },
];

export default () => {
  const navigate = useNavigate();
  const handleTileClick = () => {
    navigate('/portal/device');
  };

  const onClickMenu: MenuProps['onClick'] = ({ key }) => {
    if (key === 'signOut') {
      localStorageManager.delete('user');
      localStorageManager.delete('project');
      localStorageManager.delete('authToken');
      navigate('/login');
    }
  };

  const userName = localStorageManager.get('user')?.username;

  return (
    <div className='page-header'>
      <h1 onClick={handleTileClick}>QA Tool Platform</h1>
      <div className='right'>
        <div className='menu'>
          {userName && (
            <Dropdown menu={{ items, onClick: onClickMenu }} trigger={['click']}>
              <a onClick={e => e.preventDefault()}>
                <Space>
                  <UserOutlined style={{ color: '#f2f2f2' }} />
                  <div className='user'>{userName}</div>
                </Space>
              </a>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};
