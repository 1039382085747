import Filter from '@/components/Filters';
import { Table } from 'antd';
import { useAntdTable } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import api from '@/utils/api';

import './index.less';
import { useForm } from 'antd/es/form/Form';
import localStorageManager from '@/utils/storage';

const filterItems = [
  {
    type: 'Select' as const,
    name: 'type',
    label: 'type',
    options: [
      { label: 'Device Id', value: 'deviceId' },
      { label: 'IDFA', value: 'idfa' },
      { label: 'Brand', value: 'brand' },
      { label: 'Device Model', value: 'deviceModel' },
      { label: 'OS Version', value: 'osVersion' },
      { label: 'Recently Used by', value: 'recentUser' },
    ],
  },
  {
    type: 'Input' as const,
    name: 'inputValue',
    label: '',
    placeholder: 'Please input',
  },
];

const columns = [
  {
    title: 'IDFA',
    dataIndex: 'idfa',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
  },
  {
    title: 'Device Model',
    dataIndex: 'model',
  },
  {
    title: 'Carrier',
    dataIndex: 'carrier',
  },
  {
    title: 'OS Version',
    dataIndex: 'osVersion',
  },
  {
    title: 'Chip Manufactor',
    dataIndex: 'chipManufacturer',
  },
  {
    title: 'Country',
    dataIndex: 'store',
  },
  {
    title: 'Recently Used by',
    dataIndex: 'recentUser',
  },
];

export default () => {
  const [filterForm] = useForm();
  const navigate = useNavigate();

  const getTableData = async ({ current, pageSize }: any, formData: any) => {
    const { type, inputValue } = formData;
    const res = await api
      .get('/deviceList', { pageNo: current, pageSize, [type]: inputValue })
      .catch(() => null);

    return res;
  };

  const { tableProps, search } = useAntdTable(getTableData, { form: filterForm });

  const onClickRow = (record: any) => {
    return {
      onClick: async () => {
        localStorageManager.set('previewDevice', record);
        navigate(`/portal/device/detail/${record.deviceId}`);
        try {
          await api.put('/device', { deviceId: record.deviceId });
        } catch (error) {
          console.log(error);
        }
      },
    };
  };

  return (
    <div className='device-list-page'>
      <div className='banner'>
        <h2>QA Device List</h2>

        <Filter form={filterForm} items={filterItems} onQuery={search.submit} />
      </div>

      <div className='content'>
        <Table columns={columns} rowKey='id' {...tableProps} onRow={onClickRow} />
      </div>
    </div>
  );
};
